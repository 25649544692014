:root {
  --color-black: #000;
  --color-white: #fff;
  --color-dark: rgb(32, 32, 32);
  --color-light: rgb(24, 78, 122);
  --color-primary: rgb(16, 109, 163);
  --color-primary-dark: rgb(12, 84, 126);
  --color-primary-light: rgb(25, 120, 175);
  --color-secondary: rgb(9, 53, 88);
  --color-accent: rgb(14, 67, 110);
  overflow-x: hidden;
  scrollbar-width: none;
}

button {
  background-color: var(--color-accent);
  padding: 10px 15px;
  color: var(--color-white);
  box-shadow: rgba(0, 0, 0, 0.2) -4px 4px 4px;
  cursor: pointer;
  text-align: center;
  border: rgba(0, 0, 0, 0) 0px solid;
  border-radius: 10px;
  font-weight: 600;
}

.BigButton {
  width: 100px;
  height: 60px;
}

button:hover {
  background-color: var(--color-primary-dark);
  box-shadow: rgba(0, 0, 0, 0.3) -4px 4px 4px;
}

button:active {
  background-color: var(--color-accent);
}

button:not(:hover) {
  transition: all 0.3s ease-in-out;
}

.noStyle {
  all: unset;
}
.noStyle:hover {
  all: unset;
}

.MovieCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Header__Menu {
  display: flex;
  position: fixed;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  background-color: var(--color-secondary);
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 2px;
  padding: 10px;
}

.Header__Left {
  width: 120%;
  max-width: 1500px;
  display: flex;
  z-index: 10;
  padding: 10px;
}

.ToggleGenresButton {
  margin-right: 20px;
  width: 250px;
}

.Header__MenuIcon {
  width: 30px;
  height: 30px;
}

.Search {
  width: 60%;
  max-width: 800px;
  height: 42px;
  align-self: center;
  margin: 0 15px;
}

.GenreContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GenreTagsContainer {
  top: 100px;
  margin-bottom: 40px;
  padding: 0 20px;
  position: relative;
  justify-content: center;
  display: flex;
}

.ShowGenreButton {
  padding: 5px 20px;
  width: 200px;
}

.GenreContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
}

.GenreTags {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 25px;
  text-align: center;
  margin-bottom: 30px;
}

.GenreTagButton {
  background-color: rgba(0, 0, 0, 0.4);
  border: rgba(0, 0, 0, 0.3) 1px solid;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 4px 4px;
}

.GenreTagButton:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border: rgba(0, 0, 0, 0.3) 1px solid;
  box-shadow: rgba(0, 0, 0, 0.2) -4px 4px 4px;
}

.GenreTagButtonOn {
  background-color: rgba(0, 0, 0, 0.6);
  border: rgba(0, 0, 0, 0) 1px solid;
}

.GenreTagButtonOn:hover {
  background-color: rgba(0, 0, 0, 0.7);
  border: rgba(0, 0, 0, 0) 1px solid;
  box-shadow: rgba(0, 0, 0, 0.3) -4px 4px 4px;
}

.MovieCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin: 15% auto -5%;
  width: 400px;
  max-width: 400px;
  background-color: var(--color-dark);
  box-shadow: rgba(0, 0, 0, 0.6) -4px 6px 6px;
}

.MovieInfo {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  font-size: larger;
  background-color: rgba(0, 0, 0, 0.85);
  color: rgba(255, 255, 255, 1);
  height: 600px;
  width: 100%;
  max-width: 400px;
}

.MovieTitle {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: medium;
  background-color: rgba(0, 0, 0, 0.9);
  color: rgba(255, 255, 255, 1);
  height: 110px;
  width: 100%;
  max-width: 400px;
}

.MovieInfo h1 {
  position: absolute;
  top: -20px;
  right: 20px;
}

.MovieInfo h1:hover {
  cursor: pointer;
  scale: 120%;
  transition: all 0.3s ease-in-out;
}

.MovieInfo h1:not(hover) {
  transition: all 0.3s ease-in-out;
}

.MovieInfo h2 {
  margin-top: 60%;
}

.MovieCardInfo {
  width: 400px;
  height: 600px;
  background-color: var(--color-dark);
  cursor: pointer;
}

.MovieDetailsButton {
  font-size: x-large;
  padding: 25px 60px;
  margin-top: 15px;
  margin-bottom: 150px;
}

.MovieBox {
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  height: 600px;
  width: 100%;
  max-width: 400px;
  border-radius: 0;
}

.MovieBox:hover {
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  height: 600px;
}

.MovieBox:not(:hover) {
  transition: all 0.4s ease-in-out;
}

.ModalContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: auto;
  width: auto;
  max-width: 1200px;
  height: fit-content;
  margin-top: 5%;
  background-color: var(--color-secondary);
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.7) -14px 18px 50px;
}

.CloseModalButton {
  width: 200px;
  height: 50px;
  margin-bottom: 20px;
  font-size: larger;
}

.DetailsForm {
  width: 95%;
  height: fit-content;
  color: -var(--color-white);
}

.MovieBanner {
  width: 100%;
  margin-bottom: -10px;
}

.CastList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -10px;
}

.CastList h2 {
  margin: 0 15px;
}

.NoMoreMovies {
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 120px;
}

.Footer {
  width: 100%;
  position: fixed;
  bottom: 0px;
  height: 20px;
  background-color: var(--color-secondary);
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Footer a {
  text-decoration: none;
  color: var(--color-white);
  padding: 5px;
}

/* Set of media queries at every 420px increment
    based around fixed thumbnail size of 400px 
    scales from mobile up to 4k*/

@media screen and (min-width: 2501px) {
  .MovieCardContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .GenreTags {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 2500px) {
  .MovieCardContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .GenreTags {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .ModalContainer {
    max-width: 1000px;
  }
}

@media screen and (max-width: 2080px) {
  .MovieCardContainer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .GenreTags {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .ModalContainer {
    max-width: 750px;
  }
}

@media screen and (max-width: 1660px) {
  .MovieCardContainer {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .GenreTags {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .ModalContainer {
    max-width: 700px;
  }

}

@media screen and (max-width: 1240px) {
  .MovieCardContainer {
    grid-template-columns: 1fr 1fr;
  }
  .GenreTags {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 820px) {
  .MovieCardContainer {
    grid-template-columns: 1fr;
  }
  .MovieCard {
    max-width: 350px;
  }
  .MoviePoster {
    max-width: 350px;
  }
  .MovieBox {
    max-width: 350px;
    max-height: 525px;
  }
  .MovieInfo {
    max-width: 350px;
    max-height: 525px;
  }
  .MovieTitle {
    font-size: small;
    height: 95px;
    width: 100%;
    max-width: 350px;
  }
  .GenreTags {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .CastList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: -10px;
  }
  .CastList h2 {
    margin: 5px;
  }
  .ModalContainer {
    font-size: smaller;
    margin-top: 45px;
    padding: -10px;
  }
  .GenreContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
}
